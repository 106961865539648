<template>
  <div class="passwordReset" >
    <formTitleComponent />
      <div class="container">
        <h1 class="form_div_title text-center">Réinitialisation de votre mot de passe</h1>
        <div class="form_div">
          <div class="form_content">

            <!-- Déjà connecté -->

            <div v-if="emailsent">
              <div class="text-center">
                <div class="mail-sent-img">
                  <b-img :src="require('@/static/images/send.svg')" style="max-width: 20%;'"
                         fluid alt="Message envoyé"  />
                </div>
                <h2 class="mt-3">
                  Votre email de réinitialisation du mot de passe a bien été envoyé.
                </h2>
                <strong>Vérifiez également que l'email n'a pas atteri dans
                vos spams (courrier indésirable).</strong>
              </div>
              <div class="row mt-2">
                <div class="col-12 text-center">
                  <button class="btn btn-primary" @click="goHome" style="white-space: normal;">
                    Retour à l'accueil
                  </button>
                </div>
              </div>
            </div>
            <div v-else>
              <h2 class="text-center mt-4"> Entrez l'email associé à votre compte pour recevoir le lien de réinitialisation </h2>

              <b-form v-on:submit.prevent="resetPassword()" class="mt-4">
                <b-form-input
                  v-model="resetPasswordEmail" type="email"
                  maxlength="249"
                  placeholder="Votre email"
                  id="reset-password-email-input"
                  @input="$v.resetPasswordEmail.$touch()"
                  :state="$v.resetPasswordEmail.$dirty ? !$v.resetPasswordEmail.$error : null">
                </b-form-input>
                <small v-if="errorEmailMessage" class="text-danger"> {{errorEmailMessage}} </small>
                <b-button
                  type="submit"
                  block :variant="resetPasswordButtonStyle" class="mt-3"
                  @click='resetPassword'
                  :disabled="$v.resetPasswordEmail.$invalid || passwordResetingInProcess">
                    <b-spinner small label="Reset Password Spinner" v-if="passwordResetingInProcess"></b-spinner> {{ resetPasswordButtonText }}
                </b-button>
              </b-form>
            </div>
            <!-- ERROR MODAL -->
            <b-modal ref="GlobalAPIErrorModal" hide-footer title="Erreur globale">
              <div class="text-center">
                <p>Une erreur s'est produite, veuillez réactualiser la page ou vous reconnecter</p>
                <b-button class="mt-3" variant="outline-success" @click="hideGlobalAPIErrorModal">Fermer</b-button>
              </div>
            </b-modal>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email, minLength, maxLength } from 'vuelidate/lib/validators'
import formTitleComponent from '@/components/formTitleComponent'

import { AuthAPIService } from '@/api/APIAuth'

const apiAuth = new AuthAPIService()


export default {
  name: 'PasswordReset',
  components: {
    formTitleComponent
  },
  mixins: [
    validationMixin
  ],
  data: function () {
    return {

      resetPasswordEmail: '',
      resetPasswordButtonText: 'Réinitialiser votre mot de passe',
      resetPasswordButtonStyle: 'outline-primary',
      passwordResetingInProcess: false,

      errorEmailMessage: '',
      emailsent: false
    }
  },
  validations: {
    resetPasswordEmail: {
      required,
      email,
      maxLength: maxLength(249),
      minLength: minLength(2)
    }
  },

  methods: {
    resetPassword () {
      this.resetPasswordButtonText = 'Envoi en cours'
      this.resetPasswordButtonStyle = 'secondary'
      this.passwordResetingInProcess = true
      apiAuth.resetPassword(this.resetPasswordEmail)
      .then(() => {
          this.emailsent = true
        }, (error) => {
          if (error.response.status == 400) {
            this.errorEmailMessage = error.response.data.email[0]
          }
          else {
            this.showGlobalAPIErrorModal()
          }
        })
      .finally(() => {
        this.resetPasswordButtonText = 'Réinitialiser votre mot de passe'
        this.resetPasswordButtonStyle = 'outline-primary'
        this.passwordResetingInProcess = false
      })
    },

    showGlobalAPIErrorModal () {
      this.$refs.GlobalAPIErrorModal.show()
    },
    hideGlobalAPIErrorModal () {
      this.$refs.GlobalAPIErrorModal.hide()
    },
    goHome () {
      this.$router.push('/')
    },
  }
}
</script>
